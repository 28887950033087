<template>
    <div class="card" style="margin-bottom: 300px;">
        <div class="card-body">
            <div class="row">
                <h5
                    class="col-12 text-uppercase bg-light p-2 d-flex justify-content-between align-items-center"
                >
                    <span>statuses</span>
                    <button
                        class="btn btn-success waves-effect waves-light mr-1"
                        type="button"
                        @click="addStep"
                    >
                        <i class="fas fa-plus-circle" /> Add status
                    </button>
                </h5>
            </div>
            <div class="row">
                <draggable
                    :key="dragKey"
                    :list="steps"
                    class="col-12"
                    handle=".handle"
                    @start="drag = true"
                    @end="onMoved"
                >
                    <div
                        v-for="(step, index) in steps"
                        :key="index"
                        class="col-12 my-1"
                    >
                        <div class="col-12 mb-2">
                            <button
                                class="btn btn-danger waves-effect waves-light mr-1"
                                type="button"
                                @click="deleteElement(index)"
                            >
                                <i class="far fa-trash-alt" />
                            </button>
                            <button
                                class="handle btn btn-info waves-effect waves-light mr-1"
                                type="button"
                            >
                                <i class="fas fa-arrows-alt" />
                            </button>
                            <button
                                v-if="index"
                                class="btn btn-success waves-effect waves-light mr-1"
                                type="button"
                                @click="moveElement(index, index - 1)"
                            >
                                <i class="fas fa-arrow-up" />
                            </button>
                            <button
                                v-if="index !== value.length - 1"
                                class="btn btn-success waves-effect waves-light mr-1"
                                type="button"
                                @click="moveElement(index, index + 1)"
                            >
                                <i class="fas fa-arrow-down" />
                            </button>
                        </div>
                        <div class="col-12 row">
                            <div class="col-8 form-group">
                                <label for="status">Status</label>
                                <v-select
                                    v-model="step.statusId"
                                    :options="statuses"
                                    label="customName"
                                    :reduce="option => option.id"
                                    @input="emit"
                                />
                            </div>
                            <div class="col-4 form-group">
                                <label for="duration">Days left (regular days, not working days)</label>
                                <input
                                    id="duration"
                                    v-model="step.duration"
                                    type="number"
                                    class="form-control"
                                    @input="emit"
                                />
                            </div>
                        </div>
                        <h6 class="col-12 text-uppercase bg-light p-2" />
                    </div>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions } from 'vuex';

export default {
    components: {
        Draggable
    },

    props: {
        value: {
            type: Array,
            required: true,
            default: () => []
        },
        isRemake: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            statuses: [],
            steps: [],
            drag: false,
            dragKey: 0
        };
    },

    watch: {
        isRemake() {
            this.onIsRemakeChange();
        }
    },

    async created() {
        this.steps = this.value.map(item => ({ ...item }));

        try {
            this.statuses = await this.getAllStatuses(this.isRemake);
            this.formatStatuses();
        } catch (error) {
            console.error(error);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getAllStatuses: 'trackingStatuses/all'
        }),

        addStep() {
            this.steps = [...this.steps, {}];

            this.emit();
            this.dragKey += 1;
        },

        moveElement(from, to) {
            this.steps.splice(to, 0, this.steps.splice(from, 1)[0]);

            this.emit();
            this.dragKey += 1;
        },

        onMoved() {
            this.drag = false;
            this.emit();
            this.dragKey += 1;
        },

        deleteElement(index) {
            this.steps.splice(index, 1);

            this.emit();
            this.dragKey += 1;
        },

        emit() {
            this.$emit('input', [...this.steps]);
        },

        async onIsRemakeChange() {
            try {
                this.steps = [];
                this.emit();
                this.statuses = await this.getAllStatuses(this.isRemake);
                this.formatStatuses();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        formatStatuses() {
            this.statuses = this.statuses.map(status => {
                return {
                    ...status,
                    customName: `${status.name} - ${status.code}`
                };
            });
        }
    }
};
</script>
