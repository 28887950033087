var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modalCopyTimeline","title-class":"text-light","header-bg-variant":"dark","header-text-variant":"light","title-tag":"h4","size":"lg","centered":""},on:{"hidden":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"col-12 d-flex justify-content-between"},[_c('button',{staticClass:"btn btn-danger waves-effect waves-light mr-2",on:{"click":_vm.hideModal}},[_vm._v("\n                Cancel\n            ")])])]},proxy:true}])},[_c('template',{slot:"modal-title"},[_vm._v("\n        Copy timeline\n    ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"id":"search","type":"text","placeholder":"Search for timelines"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('awesome-table',{attrs:{"labels":['name', 'date', 'actions'],"items":_vm.timelines,"options":{
                    searchInput: false,
                    pagination: false,
                    addButton: false,
                    sort: false,
                    clickableRows: false,
                    link: false
                }},scopedSlots:_vm._u([{key:"items.actions",fn:function(ref){
                var item = ref.item;
return [_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.copy(item.id)}}},[_vm._v("\n                        Copy\n                    ")])]}}],null,true)})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }