<template>
    <b-modal
        id="modalCopyTimeline"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @hidden="hideModal"
    >
        <template slot="modal-title">
            Copy timeline
        </template>
        <div class="row">
            <div class="col-12 form-group">
                <input
                    id="search"
                    v-model="search"
                    class="form-control"
                    type="text"
                    placeholder="Search for timelines"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <awesome-table
                    :labels="['name', 'date', 'actions']"
                    :items="timelines"
                    :options="{
                        searchInput: false,
                        pagination: false,
                        addButton: false,
                        sort: false,
                        clickableRows: false,
                        link: false
                    }"
                >
                    <template #[`items.actions`]="{ item }">
                        <button class="btn btn-success" @click="copy(item.id)">
                            Copy
                        </button>
                    </template>
                </awesome-table>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            search: '',
            timelines: []
        };
    },

    watch: {
        search() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.timer = setTimeout(() => {
                this.fetchTimelines();
            }, 500);
        }
    },

    created() {
        this.fetchTimelines();
    },

    methods: {
        ...mapActions({
            getTimelines: 'timelines/index',
            getTimeline: 'timelines/show'
        }),

        async fetchTimelines() {
            if (!this.search) {
                this.timelines = [];

                return;
            }

            try {
                const { rows } = await this.getTimelines({
                    search: this.search
                });

                this.timelines = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async copy(timelineId) {
            try {
                const timeline = await this.getTimeline(timelineId);

                this.$emit('copy', timeline);
                this.hideModal();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        hideModal() {
            this.$bvModal.hide('modalCopyTimeline');
        }
    }
};
</script>
