<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card">
            <div class="card-body">
                <div class="row">
                    <button class="btn btn-success mr-2" @click="save">
                        Save
                    </button>
                    <button
                        v-if="!editMode"
                        v-b-modal.modalCopyTimeline
                        class="btn btn-info"
                    >
                        Copy existing timeline
                    </button>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="row justify-content-center">
                    <div
                        class="col-2 d-flex justify-content-center flex-column"
                    >
                        <b-form-checkbox
                            v-model="timeline.is_remake"
                            name="is_remake"
                            :disabled="editMode || timeline.is_oto_discount_accepted"
                            switch
                            @input="onIsRemakeChange"
                        >
                            Remake Timeline
                        </b-form-checkbox>
                        <span
                            v-if="errors.is_remake"
                            class="text-danger"
                        >
                            {{ errors.is_remake }}
                        </span>
                    </div>
                    <div
                        class="col-2 d-flex justify-content-center flex-column"
                    >
                        <b-form-checkbox
                            v-model="timeline.is_oto_discount_accepted"
                            name="is_remake"
                            :disabled="editMode || timeline.is_remake"
                            switch
                        >
                            OTO Discount Timeline
                        </b-form-checkbox>
                        <span
                            v-if="errors.is_oto_discount_accepted"
                            class="text-danger"
                        >
                            {{ errors.is_oto_discount_accepted }}
                        </span>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="date">
                                Date
                            </label>
                            <input
                                v-model="timeline.date"
                                :disabled="editMode || timeline.is_remake"
                                type="date"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        $v.timeline.date.$error || errors.date
                                }"
                                @input="onDateInput"
                            />
                            <span v-if="errors.date" class="invalid-feedback">
                                {{ errors.date }}
                            </span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="name">
                                Name
                            </label>
                            <input
                                v-model="timeline.name"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': $v.timeline.name.$error
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <timeline-steps
            v-if="!editMode || timeline.id"
            :key="stepsKey"
            v-model="steps"
            :is-remake="timeline.is_remake"
        />

        <copy-timeline-modal @copy="copyTimeline" />
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import { required } from 'vuelidate/lib/validators';
import TimelineSteps from '@components/timelines/TimelineSteps';
import CopyTimelineModal from '@components/timelines/CopyTimelineModal';

export default {
    validations: {
        timeline: {
            date: {
                required: (v, obj) => {
                    if (obj.is_remake) {
                        return true;
                    }

                    return !!v;
                }
            },
            name: {
                required
            }
        }
    },

    page: {
        title: 'Timelines',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        TimelineSteps,
        CopyTimelineModal
    },

    data() {
        const { id } = this.$route.params;

        return {
            title: 'Add timeline',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Timelines',
                    to: '/timelines'
                },
                {
                    text: 'Manage timeline',
                    active: true
                }
            ],
            id,
            editMode: !!id,
            timeline: {},
            steps: [],
            errors: {},
            stepsKey: 0
        };
    },

    async created() {
        if (this.editMode) {
            this.title = 'Edit timeline';

            if (this.$route.hash === '#added') {
                this.$toaster('Timeline has been added!');
            }

            try {
                const timeline = await this.getTimeline(this.id);

                this.steps = timeline.statuses.map(item => ({
                    duration: item.duration,
                    statusId: item.tracking_status_id
                }));

                this.timeline = {
                    id: timeline.id,
                    date: timeline.date,
                    name: timeline.name,
                    is_remake: timeline.is_remake,
                    is_oto_discount_accepted: timeline.is_oto_discount_accepted
                };
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    },

    methods: {
        ...mapActions({
            getTimeline: 'timelines/show',
            createTimeline: 'timelines/store',
            updateTimeline: 'timelines/update'
        }),

        async save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const data = {
                    ...this.timeline,
                    items: this.steps
                };

                if (this.editMode) {
                    await this.updateTimeline({ id: this.id, data });

                    this.$toaster('Timeline has been updated!');

                    return;
                }

                const timeline = await this.createTimeline(data);

                this.$router.push(`/timelines/${timeline.id}/edit#added`);
            } catch (error) {
                console.error(error);

                if (
                    error.response &&
                    error.response.status === 422 &&
                    error.response.data.errors
                ) {
                    const { errors } = error.response.data;

                    for (const key in errors) {
                        errors[key] = errors[key].join(' ');
                    }

                    this.errors = { ...errors };
                } else {
                    const message =
                        error.response &&
                        error.response.data &&
                        error.response.data.error
                            ? error.response.data.error
                            : 'Something went wrong!';

                    this.$toasterError(message);
                }
            }
        },

        copyTimeline(timeline) {
            const { statuses } = timeline;

            this.steps = statuses.map(status => ({
                statusId: status.tracking_status_id,
                duration: status.duration
            }));

            this.stepsKey++;
        },

        onDateInput() {
            this.errors.date = null;

            if (this.editMode || this.timeline.name) {
                return;
            }

            this.timeline.name = this.timeline.date;
        },

        onIsRemakeChange(val) {
            this.errors = {};

            if (val) {
                this.timeline.date = '';
            }
        }
    }
};
</script>
